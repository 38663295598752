var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.promptHeaderText))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('name'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.name),callback:function ($$v) {_vm.$set(_vm.stakeholder, "name", $$v)},expression:"stakeholder.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('vatNumber'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.vatNumber),callback:function ($$v) {_vm.$set(_vm.stakeholder, "vatNumber", $$v)},expression:"stakeholder.vatNumber"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('isBoardMember'),"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.isBoardMember),callback:function ($$v) {_vm.$set(_vm.stakeholder, "isBoardMember", $$v)},expression:"stakeholder.isBoardMember"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('taxOffice'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.taxOffice),callback:function ($$v) {_vm.$set(_vm.stakeholder, "taxOffice", $$v)},expression:"stakeholder.taxOffice"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.stakeholder.isCompany)?_c('v-checkbox',{attrs:{"label":_vm.$t('isEmployee'),"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.isEmployee),callback:function ($$v) {_vm.$set(_vm.stakeholder, "isEmployee", $$v)},expression:"stakeholder.isEmployee"}}):_vm._e(),(_vm.stakeholder.isEmployee)?_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }}},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":_vm.$t('isFullTimeEmployee'),"readonly":_vm.mode == 'view'},model:{value:(_vm.isFullTimeEmployee),callback:function ($$v) {_vm.isFullTimeEmployee=$$v},expression:"isFullTimeEmployee"}},[_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("isFullTimeEmployeeTooltip")))])])],1)],1):_vm._e(),_c('v-checkbox',{attrs:{"label":_vm.$t('isCompany'),"readonly":_vm.mode == 'view'},on:{"change":function($event){return _vm.companyChanged()}},model:{value:(_vm.stakeholder.isCompany),callback:function ($$v) {_vm.$set(_vm.stakeholder, "isCompany", $$v)},expression:"stakeholder.isCompany"}})],1),_c('div',{staticClass:"h6 mt-6"},[_vm._v(_vm._s(_vm.$t("contact")))]),_c('v-divider',{staticClass:"mb-4"}),(!_vm.stakeholder.isCompany)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('firstName'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.firstName),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact, "firstName", $$v)},expression:"stakeholder.contact.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('lastName'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.lastName),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact, "lastName", $$v)},expression:"stakeholder.contact.lastName"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.stakeholder.isCompany)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Legal Entity Name'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.firstName),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact, "firstName", $$v)},expression:"stakeholder.contact.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('crnNumber'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.crnNumber),callback:function ($$v) {_vm.$set(_vm.stakeholder, "crnNumber", $$v)},expression:"stakeholder.crnNumber"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('email'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.email),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact, "email", $$v)},expression:"stakeholder.contact.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('phone'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.phone),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact, "phone", $$v)},expression:"stakeholder.contact.phone"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"h6 mt-6"},[_vm._v(_vm._s(_vm.$t("address")))]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('country'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.address.country),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact.address, "country", $$v)},expression:"stakeholder.contact.address.country"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('addressState'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.address.state),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact.address, "state", $$v)},expression:"stakeholder.contact.address.state"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('city'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.address.city),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact.address, "city", $$v)},expression:"stakeholder.contact.address.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('streetAddress'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.address.streetAddress),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact.address, "streetAddress", $$v)},expression:"stakeholder.contact.address.streetAddress"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('postCode'),"error-messages":errors,"readonly":_vm.mode == 'view'},model:{value:(_vm.stakeholder.contact.address.postCode),callback:function ($$v) {_vm.$set(_vm.stakeholder.contact.address, "postCode", $$v)},expression:"stakeholder.contact.address.postCode"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-10"},[_c('v-col',[_c('v-btn',{staticClass:"min-width-2 mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),(_vm.mode == 'edit' || _vm.mode=='create')?_c('v-btn',{staticClass:"min-width-2",attrs:{"color":"secondary","depressed":"","disabled":invalid,"loading":_vm.loading},on:{"click":_vm.commit}},[_vm._v(" "+_vm._s(_vm.commitButtonText)+" ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }