
























import Vue from "vue";
import TabStakeholders from "./TabStakeholders.vue";

const tabIndexMap: Record<string, number> = {
  "stakeholders": 0,
  "optionsholders": 1,
  "shareholders": 2,
  "directors": 3
}

export default Vue.extend({
  components: {
    TabStakeholders    
  },
  props:{
    tab:{
      type: String,
      required: false,
      default: "stakeholders"
    }
  },
  data(){
    return {
      tabValue: tabIndexMap[this.tab]
    }
  }
});
