






































import Vue, { PropType } from "vue";
import {
  createEmailForAllStakeholders,
  deleteStakeholder,
  getStakeholdersOfCompany,
  StakeholderDto,
  StakeholderType,
} from "@/service/stakeholders/stakeholders.service";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import {
  ActionableItem,
  MenuAction,
  MenuActions,
  toActionableItem,
} from "@/components/menu-actions";
import Grid from "@/components/grid/Grid.vue";
import { revokeUserInvitation, sendUserInvitation } from "@/service/user/user.service";

export default Vue.extend({
  components: {
    MenuActions,
    Grid,
  },
  props: {
    type: {
      type: String as PropType<StakeholderType>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("name").toString(),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("contact").toString(),
          align: "start",
          sortable: false,
          value: "contact",
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      items: [] as Array<StakeholderDto>,
      actions: [] as Array<MenuAction>,
    };
  },
  computed: {
    actionableItems(): Array<ActionableItem<StakeholderDto>> {
      return this.items.map((x) => {
        const itemView = toActionableItem(x);

        if (this.$can("delete", "Stakeholder"))
          itemView.actions.push({
            text: this.$t("delete").toString(),
            clickHandler: this.onDelete,
          });
        if (x.user?.invitation && !x.user.invitation.accepted) {
          if (this.$can("delete", "StakeholderInvitation"))
            itemView.actions.push({
              text: this.$t("revokeInvitation").toString(),
              clickHandler: this.onRevokeInvitation,
            });
        }
        else if (x.user?.invitation) {
          if (this.$can("create", "StakeholderInvitation"))
            itemView.actions.push({
              text: this.$t("resendInvitation").toString(),
              clickHandler: this.onSendInvitation,
            });

        }
        else {
          if (this.$can("create", "StakeholderInvitation"))
            itemView.actions.push({
              text: this.$t("sendInvitation").toString(),
              clickHandler: this.onSendInvitation,
            });
        }


        return itemView;
      });
    },
  },
  async mounted() {
    this.populateActions();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        var result = await getStakeholdersOfCompany(
          companiesStore.state.currentId,
          ["contact"],
          this.type
        );
        this.items = result;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async onSendInvitation(item: StakeholderDto) {
      if (!item.user)
        return;

      try {
        this.loading = true;
        const invitation = await sendUserInvitation({
          userId: item.user.id,
          signUpUrl: `${location.origin}/sign-up?token={token}&email={email}`
        });
        Vue.set(item.user, 'invitation', invitation);
        appStore.snackbar(this.$t("shared.successfulExecution").toString(), "success");
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
      finally {
        this.loading = false;
      }
    },
    async onRevokeInvitation(item: StakeholderDto) {
      if (!item.user?.invitation)
        return;

      try {
        this.loading = true;
        await revokeUserInvitation(item.user.invitation.id);
        Vue.delete(item.user, 'invitation');
        appStore.snackbar(this.$t("shared.successfulExecution").toString(), "success");
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
      finally {
        this.loading = false;
      }
    },
    async onDelete(item: StakeholderDto) {
      try {
        this.loading = true;
        await deleteStakeholder(item.id);
        const index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        this.loading = false;
        appStore.snackbar(this.$t("shared.successfulExecution").toString(), "success");
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async sendEmailToAll() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        await createEmailForAllStakeholders(companiesStore.state.currentId);
        this.loading = false;
        appStore.snackbar(this.$t("shared.successfulExecution").toString(), "success");
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    addStakeholder() {
      this.$router.push("/create-stakeholder");
    },
    populateActions() {
      if (this.$can("create", "Stakeholder"))
        this.actions.push({
          text: this.$t("shared.add").toString(),
          clickHandler: this.addStakeholder,
        });

      // Temp remove it until we know more about the feature
      // if (this.$can("create", "StakeholderEmailNotification"))
      //   this.actions.push({
      //     text: this.$t("emailAll").toString(),
      //     clickHandler: this.sendEmailToAll,
      //   });
    },
  },
});
