





































































































































































































































import Vue from "vue";
import {
  getStakeholder,
  updateStakeholder,
  StakeholderDto,
  createStakeholder,
} from "@/service/stakeholders/stakeholders.service";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      mode: "view" as "view" | "edit" | "create",
      loading: false,
      isFullTimeEmployee: false,
      stakeholder: {
        id: "",
        name: "",
        isBoardMember: false,
        isEmployee: false,
        type: "individual",
        vatNumber: "",
        crnNumber: "",
        isCompany: false,
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          address: {
            country: "",
            state: "",
            city: "",
            streetAddress: "",
            postCode: "",
          },
        },
      } as StakeholderDto,
    };
  },
  computed: {
    commitButtonText(): string {
      return this.mode == "edit"
        ? this.$t("update").toString()
        : this.$t("create").toString();
    },
    promptHeaderText(): string {
      switch (this.mode) {
        case "view":
          return "";
        case "edit":
          return this.$t("stakeholdersModule.updateStakeholder").toString();
        case "create":
          return this.$t("stakeholdersModule.addNewStakeholder").toString();
        default:
          return "";
      }
    },
  },
  async mounted() {
    
    this.mode = 'view'

    if (this.id && this.$can('update', 'Stakeholder')){
      this.mode = 'edit'
    }

    if (!this.id && this.$can('create', 'Stakeholder')){
      this.mode = 'create'
    }

    if (this.mode == "edit" || this.mode == "view") {
      try {
        this.loading = true;
        this.stakeholder = await getStakeholder(this.id);

        // Assume it's a full-time employee since he is in the system
        this.isFullTimeEmployee = this.stakeholder.isEmployee;

        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    }
  },
  methods: {
    async commit() {
      if (this.mode == "edit") {
        try {
          this.loading = true;
          await updateStakeholder(this.stakeholder);
          this.loading = false;
          appStore.snackbar(this.$t("shared.ok").toString(), "success");
          this.$router.go(-1);
        } catch (error) {
          this.loading = false;
          appStore.snackbar(this.$t("shared.genericError").toString(), "error");
        }
      } else if (this.mode == "create") {
        try {
          this.loading = true;
          this.stakeholder = await createStakeholder(
            companiesStore.state.currentId,
            this.stakeholder
          );
          this.loading = false;
          appStore.snackbar(this.$t("shared.ok").toString(), "success");
          this.$router.go(-1);
        } catch (error) {
          this.loading = false;
          appStore.snackbar(this.$t("shared.genericError").toString(), "error");
        }
      }
    },
    async companyChanged() {
      this.isFullTimeEmployee = !this.stakeholder.isCompany
      this.stakeholder.isEmployee = !this.stakeholder.isCompany
    }
  },
});
